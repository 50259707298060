import React from "react"
import Layout from "../components/layout"
import "./index.css"
import Hero from "../components/hero"
import WorksHome from "../components/worksHome"
import Services from "../components/services"
import Testimonials from "../components/testimonials"
import Contact from "../components/contact"

export default () => {
  const lang = "en"
  return (
    <Layout
      lang={lang}
      pageUrl=""
      seoTitle="We create: web sites, apps, digital strategies - Swiss Web Agency"
      seoDescription="Webpanda is a Swiss agency specialized in all things digital. Let us help you with your next website, application or digital strategy!"
    >
      <div id="hero-background">
        <Hero lang={lang} />
      </div>
      <div id="works-background">
        <WorksHome lang={lang} />
      </div>
      <div id="services-background">
        <Services lang={lang} />
      </div>
      <div id="testimonials-background">
        <Testimonials lang={lang} />
      </div>
      <div id="contact-background">
        <Contact lang={lang} />
      </div>
    </Layout>
  )
}
