import React from "react"
import { Link } from "gatsby"
import "./tile.css"

export default props => (
  <Link to={props.linkTo}>
    <div className={props.display ? "display" : "hidden"}>
      <div className="portfolio-tile" id={props.backgroundId}>
        <div className="tile-titles">
          <h3>{props.tileTitle}</h3>
          <h6>{props.tileCategory}</h6>
        </div>
      </div>
    </div>
  </Link>
)
