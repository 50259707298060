import React from "react"
import "./hero.css"
import Phone from "../../static/phone.png"
import Vid from "../../static/animation2.mp4"
import Dict from "../langs/dict"

export default props => {
  const dict = new Dict(props.lang)
  return (
    <div id="hero-holder">
      <div id="hero-titles">
        <h2>
          {dict.getTrans("you_bring")}
          <span className="fat">{dict.getTrans("idea")}</span>. <br />
          {dict.getTrans("we_bring")}
          <span className="fat">{dict.getTrans("life")}</span>.
        </h2>
        <h4>
          {dict.getTrans("we_are")}{" "}
          <span className="green-hl">{dict.getTrans("website")}</span>,{" "}
          <span className="pink-hl">{dict.getTrans("application")}</span>{" "}
          {dict.getTrans("we_are_connector")}{" "}
          <span className="orange-hl">{dict.getTrans("digital_strategy")}</span>
          .
        </h4>
      </div>
      <div id="phone-holder">
        <img id="hero-phone" src={Phone} alt="doodle phone" alt="phone-frame" />
        <video autoPlay id="phone-gif" alt="design-process" muted>
          <source src={Vid} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
