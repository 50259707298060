import React from "react"
import "./worksHome.css"
import Tile from "../components/tile"
import LeftSectionTitle from "../components/leftSectionTitle"
import Dict from "../langs/dict"
import { Link } from "gatsby"

export default class worksHome extends React.Component {
  constructor(props) {
    super(props)
    this.dict = new Dict(props.lang)
    this.state = {
      filter: "all",
    }
  }

  handleFilterClick = e => {
    this.setState({
      filter: e.target.getAttribute("workcategory"),
    })
  }

  render() {
    return (
      <div id="works-holder">
        <LeftSectionTitle
          title={this.dict.getTrans("our_work_title")}
          subTitle={this.dict.getTrans("our_work_text")}
        />
        <div id="filters">
          <ul>
            <li
              id={this.state.filter == "all" ? "selected" : null}
              onClick={this.handleFilterClick}
              workcategory="all"
            >
              {this.dict.getTrans("work_filter_1")}
            </li>
            <li
              id={this.state.filter == "websites" ? "selected" : null}
              onClick={this.handleFilterClick}
              workcategory="websites"
            >
              {this.dict.getTrans("work_filter_2")}
            </li>
            <li
              id={this.state.filter == "applications" ? "selected" : null}
              onClick={this.handleFilterClick}
              workcategory="applications"
            >
              {this.dict.getTrans("work_filter_3")}
            </li>
            <li
              id={this.state.filter == "branding" ? "selected" : null}
              onClick={this.handleFilterClick}
              workcategory="branding"
            >
              {this.dict.getTrans("work_filter_4")}
            </li>
          </ul>
        </div>
        <div className="workshome-portfolio-container">
          <Tile
            linkTo={this.dict.getTrans("tft_link")}
            tileTitle={this.dict.getTrans("tft_title")}
            tileCategory={this.dict.getTrans("tft_category")}
            backgroundId="tft-tile"
            display={
              this.state.filter == "all" || this.state.filter == "applications"
                ? true
                : false
            }
          />
          <Tile
            linkTo={this.dict.getTrans("ppi_link")}
            tileTitle={this.dict.getTrans("ppi_title")}
            tileCategory={this.dict.getTrans("ppi_category")}
            backgroundId="ppi-tile"
            display={
              this.state.filter == "all" || this.state.filter == "websites"
                ? true
                : false
            }
          />
          <Tile
            linkTo={this.dict.getTrans("lyr_app_link")}
            tileTitle={this.dict.getTrans("lyr_app_title")}
            tileCategory={this.dict.getTrans("lyr_app_category")}
            backgroundId="lyrique-tile"
            display={
              this.state.filter == "all" || this.state.filter == "applications"
                ? true
                : false
            }
          />
          <Tile
            linkTo={this.dict.getTrans("sus_link")}
            tileTitle={this.dict.getTrans("sus_title")}
            tileCategory={this.dict.getTrans("sus_category")}
            backgroundId="sus-tile"
            display={
              this.state.filter == "all" || this.state.filter == "branding"
                ? true
                : false
            }
          />
          <Tile
            linkTo={this.dict.getTrans("ehl_link")}
            tileTitle={this.dict.getTrans("ehl_title")}
            tileCategory={this.dict.getTrans("ehl_category")}
            backgroundId="ehlvr-tile"
            display={
              this.state.filter == "all" || this.state.filter == "websites"
                ? true
                : false
            }
          />

          <Tile
            linkTo={this.dict.getTrans("ani_link")}
            tileTitle={this.dict.getTrans("ani_title")}
            tileCategory={this.dict.getTrans("ani_category")}
            backgroundId="ani-tile"
            display={
              this.state.filter == "all" || this.state.filter == "websites"
                ? true
                : false
            }
          />
        </div>
        <div id="more">
          <Link to={this.dict.getTrans("work_more_link")}>
            <div id="button">{this.dict.getTrans("work_more")}</div>
          </Link>
        </div>
      </div>
    )
  }
}
