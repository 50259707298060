import React from "react"
import "./testimonials.css"
import Dict from "../langs/dict"
import alex from "../../static/avatar1.png"
import jason from "../../static/avatar2.png"
import flo from "../../static/avatar3.png"

export default class Testimoninals extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testimonialIndex: 0,
    }
    this.testimonialMax = 2
    this.handleRight = this.handleRight.bind(this)
    this.handleLeft = this.handleLeft.bind(this)
    this.dict = new Dict(props.lang)
  }

  handleRight(event) {
    if (this.state.testimonialIndex < this.testimonialMax) {
      this.setState({
        testimonialIndex: this.state.testimonialIndex + 1,
      })
    }
  }

  handleLeft(event) {
    if (this.state.testimonialIndex > 0) {
      this.setState({
        testimonialIndex: this.state.testimonialIndex - 1,
      })
    }
  }

  render() {
    return (
      <div id="testimonial-holder">
        <h3 id="testi-title">{this.dict.getTrans("testi_title")}</h3>
        <div id="slider-holder">
          <div className="slider-button" onClick={this.handleLeft}>
            〈
          </div>
          <div id="slider">
            <div id="belt" className={"belt" + this.state.testimonialIndex}>
              <div className="belt-section">
                <h3>"{this.dict.getTrans("testi_1")}"</h3>
                <div className="avatar">
                  <img src={jason} alt="testimonial-avatar" />
                  <h6>{this.dict.getTrans("testi_1_person")}</h6>
                </div>
              </div>
              <div className="belt-section">
                <h3>"{this.dict.getTrans("testi_2")}"</h3>
                <div className="avatar">
                  <img src={flo} alt="testimonial-avatar" />
                  <h6>{this.dict.getTrans("testi_2_person")}</h6>
                </div>
              </div>
              <div className="belt-section">
                <h3>"{this.dict.getTrans("testi_3")}"</h3>
                <div className="avatar">
                  <img src={alex} alt="testimonial-avatar" />
                  <h6>{this.dict.getTrans("testi_3_person")}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-button" onClick={this.handleRight}>
            〉
          </div>
        </div>
      </div>
    )
  }
}
